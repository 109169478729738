import moment, { Moment } from "moment";
import { parseBool, parseInt, parseToMoment } from "../utility/utilityFunctions";


// load env specific runtime config and export it as a single config object
// start with a standard default config object
const config: any = {
  version: '',
  pkgName: '',
  deployEnv: "development",
  debugMode: true,
  debugAem: true,
  splunkLoggerAppName:"EasyCheckWeb",
  enableEligibilityFeature: false,                  // set to true if we want to check insurance eligibility and show related UI
  enableMaintenancePage: false,                     // set to true if we want to display the maintenance page instead of the normal application
  cdo: {
    id: "Cali",                                     // cdo id is the CdoShortName or CdoType
    uid: "e93c86de-66c5-4c72-af62-2bc4564a7ca8",    // cdo uid is the CdoUid value (for when Multi-CDO feature is used)
    system: "EasyCheckCaliApptId"
    // other CDO specific data can go here
  },
  analytics: {
    url: "",
  },
  // app inactivity timeouts
  timeouts: {
    default: {
      timeoutSecs: 30*60,   // timeout time
      warningSecs:    60,   // warning time
    },
    kiosk: {                // this kiosk timeouts config is really for public devices, not specifically kiosk mode (which may be on a private device)
      timeoutSecs:  2*60,   // timeout time
      warningSecs:    30,   // warning time
    },  
  },
  api10: {
    url: "https://fhirservice-dev.optum.com/api/v1.0/",
    code: "qdmaHEKyavNosnul5q4bKoale9RednQAhSz3322UWPY30YTR3qWHjQ==",
    version: "1.0",
  },
  api11: {
    url: "https://fhirservice-dev.optum.com/api/v1.1/",
    code: "qdmaHEKyavNosnul5q4bKoale9RednQAhSz3322UWPY30YTR3qWHjQ==",
    version: "1.1",
  },
  pamiSearchApi: {
    url: "https://pami-search-service.optum.com/api/",
    code: "",
    version: "",
  },
  validationApi: {
    url: "https://patient-validation-service-dev.optum.com/api/",
    code: "",
    version: "",
  },
  addressLookupApi: {
    url: "https://us-autocomplete-pro.api.smartystreets.com/lookup",
    id: "a29a5940-51d9-430b-20ae-0342a2be6d0d",
    token: "MxJ0heKh6jPiIcxwvCjf",
  },
  signConsentFormsApi: {
    url: "https://ec-signpdfforms-dev.optum.com/api/",
    code: "DXdbzTsNsKHVmKU6QJ/gFaKnoU5BI7iiCDvHxovdWRAo026F3C8M0w==",
  },
  aemGraphqlApi: {
    // url: "https://easycheck-aemproxy.optum.com/api/aemproxy",
    // url: "https://aem-proxy.optum.com/api/aemproxy",
    url: "https://easycheck-aemproxy-dev.optum.com/api/aemproxy",
    code: "",
  },
  aemMediaUrl: {
    url: "https://dev-acadia.optum.com",
  },
  guarantorApi: {
    url: "https://fhirservice-dev.optum.com/api/getGuarantor",
  },
  saveGuarantorApi: {
    url: "https://fhirservice-dev.optum.com/api/SaveGuarantor",
  },
  appointmentApi: {
    url: "https://easycheck-cosmosdb-api-dev.optum.com/api/v1.0/",
  },
  updatePMSApi: {
    url: "https://update-pms-dev.optum.com/api/v1.0/cali",
  },
  uspsAddressValidationLink: {
    url: "https://patient-validation-service-dev.optum.com/api/addresses/validate",
  },
  cosmosApi20: {
    url: "https://easycheck-cosmosdb-api-dev.optum.com/api/v2.0/",
  },
  updatePMSApi10: {
    url: "https://update-pms-dev.optum.com/api/v1.0/",
    code: "",
  },
  updatePMSApi20: {
    url: "https://update-pms-dev.optum.com/api/v2.0/",
    code: "",
  },
  smsApi20: {
    url: "https://sms-generator-dev.optum.com/api/v2.0/",
    code:"U/2S/3bYTAjvsOziWVfPjrmb73DcQrmIYZbXC8cjvsNF7wtvhPaP6w=="
  },
  splunkLoggerApi: {
    url: "https://appt-status-syn-service-dev.azurewebsites.net/api/v1.0/",
    code: "N2_zBu5mFjAhLkn8YIsCxIJlISINSXvC9vIdJz99GuRYAzFuc4I3eQ==",
  },
  eligibilityApi: {
    url: "https://eligibilty-proxy-nonprod.optum.com/api/",
    code: "HWxQtJvhL1fqzpeAmdk_cxXZxnmjCRQlNEwTzTFL9Hd8AzFu0McClA=="
  },  
};

/*
if (process.env.NODE_ENV === "development") {
  // localhost overrides
  config.signConsentFormsApi.url = "http://localhost:7071/api/";
  config.signConsentFormsApi.code = "";
}
//*/

/*
if (process.env.NODE_ENV === "development") {
  // local dev override for adobe analytics
  config.analytics.url = "https://assets.adobedtm.com/512027f42d3c/3f2c07df6ae4/launch-dab96c753697-development.min.js";
}
//*/

// create and merge a runtime config object from env variables
// for any create-react-app, env parameters should be on the process.env object
// and start with REACT_APP_*
if (process?.env) {
  let env = process.env;
  if (env.REACT_APP_VERSION) { 
    config.version = env.REACT_APP_VERSION;
  }  
  if (env.REACT_APP_PKG_NAME) { 
    config.pkgName = env.REACT_APP_PKG_NAME;
  }  
  if (env.REACT_APP_DEPLOY_ENV) { 
    config.deployEnv = env.REACT_APP_DEPLOY_ENV;
  }  
  if (env.REACT_APP_DEBUG_MODE) { 
    config.debugMode = parseBool(env.REACT_APP_DEBUG_MODE, config.debugMode);
  }
  if (env.REACT_APP_AEM_DEBUG) { 
    config.debugAem = parseBool(env.REACT_APP_AEM_DEBUG, config.debugAem);
  }
  if (env.REACT_APP_ENABLE_ELIGIBILITY_FEATURE) { 
    config.enableEligibilityFeature = parseBool(env.REACT_APP_ENABLE_ELIGIBILITY_FEATURE, config.enableEligibilityFeature);
  }
  if (env.REACT_APP_ENABLE_MAINTENANCE_PAGE) {
    let val: string | boolean = env.REACT_APP_ENABLE_MAINTENANCE_PAGE;
    if (val === 'auto') {
      val = false;
      let now = moment();
      let startDateTime: Moment | undefined = parseToMoment(env.REACT_APP_AUTO_MAINTENANCE_PAGE_START_DATETIME_UTC);
      let endDateTime: Moment | undefined = parseToMoment(env.REACT_APP_AUTO_MAINTENANCE_PAGE_END_DATETIME_UTC);
      val = !!(startDateTime && endDateTime && now.isBetween(startDateTime, endDateTime));
    }
    config.enableMaintenancePage = parseBool(val, config.enableMaintenancePage);
  }

  // parse inactivity timeout config values
  if (env.REACT_APP_DEFAULT_TIMEOUT_SECS) {
    config.timeouts.default.timeoutSecs = parseInt(
      env.REACT_APP_DEFAULT_TIMEOUT_SECS, 
      config.timeouts.default.timeoutSecs
    );
  }
  if (env.REACT_APP_DEFAULT_WARNING_SECS) {
    config.timeouts.default.warningSecs = parseInt(
      env.REACT_APP_DEFAULT_WARNING_SECS, 
      config.timeouts.default.warningSecs
    );
  }
  if (env.REACT_APP_KIOSK_TIMEOUT_SECS) {
    config.timeouts.kiosk.timeoutSecs = parseInt(
      env.REACT_APP_KIOSK_TIMEOUT_SECS, 
      config.timeouts.kiosk.timeoutSecs
    );
  }
  if (env.REACT_APP_KIOSK_WARNING_SECS) {
    config.timeouts.kiosk.warningSecs = parseInt(
      env.REACT_APP_KIOSK_WARNING_SECS, 
      config.timeouts.kiosk.warningSecs
    );
  }
  
  // parse API config values
  if (env.REACT_APP_CDO_ID) {
    config.cdo.id = env.REACT_APP_CDO_ID;
  }
  if (env.REACT_APP_CDO_UID) {
    config.cdo.uid = env.REACT_APP_CDO_UID;
  }
  if (env.REACT_APP_CDO_SYSTEM) {
    config.cdo.system = env.REACT_APP_CDO_SYSTEM;
  }
  if (env.REACT_APP_ANALYTICS_URL) {
    config.analytics.url = env.REACT_APP_ANALYTICS_URL;
  }
  if (env.REACT_APP_API10_URL) {
    config.api10.url = env.REACT_APP_API10_URL;
  }
  if (env.REACT_APP_API10_CODE) {
    config.api10.code = env.REACT_APP_API10_CODE;
  }
  if (env.REACT_APP_API11_URL) {
    config.api11.url = env.REACT_APP_API11_URL;
  }
  if (env.REACT_APP_API11_CODE) {
    config.api11.code = env.REACT_APP_API11_CODE;
  }
  if (env.REACT_APP_PAMI_SEARCH_API_URL) {
    config.pamiSearchApi.url = env.REACT_APP_PAMI_SEARCH_API_URL;
  }
  if (env.REACT_APP_VALIDATION_API_URL) {
    config.validationApi.url = env.REACT_APP_VALIDATION_API_URL;
  }
  if (env.REACT_APP_SIGN_COSENTFORMS_API_URL) {
    config.signConsentFormsApi.url = env.REACT_APP_SIGN_COSENTFORMS_API_URL;
  }
  if (env.REACT_APP_SIGN_COSENTFORMS_API_CODE) {
    config.signConsentFormsApi.code = env.REACT_APP_SIGN_COSENTFORMS_API_CODE;
  }
  if (env.REACT_APP_ADDRESSLOOKUP_API_URL) {
    config.addressLookupApi.url = env.REACT_APP_ADDRESSLOOKUP_API_URL;
  }
  if (env.REACT_APP_ADDRESSLOOKUP_API_ID) {
    config.addressLookupApi.id = env.REACT_APP_ADDRESSLOOKUP_API_ID;
  }
  if (env.REACT_APP_ADDRESSLOOKUP_API_TOKEN) {
    config.addressLookupApi.token = env.REACT_APP_ADDRESSLOOKUP_API_TOKEN;
  }
  if (env.REACT_APP_AEM_GRAPHQL_API_URL) {
    config.aemGraphqlApi.url = env.REACT_APP_AEM_GRAPHQL_API_URL;
  }
  if (env.REACT_APP_AEM_GRAPHQL_API_CODE) {
    config.aemGraphqlApi.code = env.REACT_APP_AEM_GRAPHQL_API_CODE;
  }
  if (env.REACT_APP_AEM_MEDIA_URL) {
    config.aemMediaUrl.url = env.REACT_APP_AEM_MEDIA_URL;
  }
  if (env.REACT_APP_GETGUARANTOR_API_URL) {
    config.guarantorApi.url = env.REACT_APP_GETGUARANTOR_API_URL;
  }
  if (env.REACT_APP_SAVEGUARANTOR_API_URL) {
    config.saveGuarantorApi.url = env.REACT_APP_SAVEGUARANTOR_API_URL;
  }
  if (env.REACT_APP_APPOINTMENT_API_URL) {
    config.appointmentApi.url = env.REACT_APP_APPOINTMENT_API_URL;
  }
  if (env.REACT_APP_UPDATEPMSAPI_API_URL) {
    config.updatePMSApi.url = env.REACT_APP_UPDATEPMSAPI_API_URL;
  }
  if (env.REACT_APP_USPSADDRESSVALIDATIONLINK_API_URL) {
    config.uspsAddressValidationLink.url = env.REACT_APP_USPSADDRESSVALIDATIONLINK_API_URL;
  }
  if (env.REACT_APP_COSMOS_API20_URL) {
    config.cosmosApi20.url = env.REACT_APP_COSMOS_API20_URL;
  }
  if (env.REACT_APP_UPDATEPMS_API10_URL) {
    config.updatePMSApi10.url = env.REACT_APP_UPDATEPMS_API10_URL;
  }
  if (env.REACT_APP_UPDATEPMS_API10_CODE) {
    config.updatePMSApi10.code = env.REACT_APP_UPDATEPMS_API10_CODE;
  }
  if (env.REACT_APP_UPDATEPMS_API20_URL) {
    config.updatePMSApi20.url = env.REACT_APP_UPDATEPMS_API20_URL;
  }
  if (env.REACT_APP_UPDATEPMS_API20_CODE) {
    config.updatePMSApi20.code = env.REACT_APP_UPDATEPMS_API20_CODE;
  }
  if (env.REACT_APP_SMS_API20_URL) {
    config.smsApi20.url = env.REACT_APP_SMS_API20_URL;
  }
  if (env.REACT_APP_SMS_API20_CODE) {
    config.smsApi20.code = env.REACT_APP_SMS_API20_CODE;
  }
  if (env.REACT_APP_SPLUNKLOGGER_API_URL) {
    config.splunkLoggerApi.url = env.REACT_APP_SPLUNKLOGGER_API_URL;
  }
  if (env.REACT_APP_SPLUNKLOGGER_API_CODE) {
    config.splunkLoggerApi.code = env.REACT_APP_SPLUNKLOGGER_API_CODE;
  }
  if (env.REACT_APP_SPLUNKLOGGER_APP_NAME) { 
    config.splunkLoggerAppName = env.REACT_APP_SPLUNKLOGGER_APP_NAME;
  } 
  if (env.REACT_APP_ELIGIBILITY_API_URL) { 
    config.eligibilityApi.url = env.REACT_APP_ELIGIBILITY_API_URL;
  }
  if (env.REACT_APP_ELIGIBILITY_API_CODE) { 
    config.eligibilityApi.code = env.REACT_APP_ELIGIBILITY_API_CODE;
  }
}

export default config;
