import { PciDocTypes } from "../docData";
import { AppointmentTypeIds } from "../Fhir";
import { makeKeysMap } from "../../utility/utilityFunctions";
import { CaliVisitTypeNames } from "./CaliVisitTypes";


// Cali CDO specific constants and definitions

export const CaliDocTypeToPciDocTypeMap = {
  "Registration**":                             PciDocTypes.REGISTRATION,
  "Covered Services Notice Annual Exam**":      PciDocTypes.COVERED_SERVICES,
  "Open Payment Forms":                         PciDocTypes.OPEN_PAYMENTS,
  "Advance Directives**":                       PciDocTypes.ADVANCE_DIRECTIVES,
  "HIPAA/Notice of Privacy Practice":           PciDocTypes.HIPAA,
};

export const CaliSupportedPciDocTypes: PciDocTypes[] = Object.values(CaliDocTypeToPciDocTypeMap);

const VT = makeKeysMap(CaliVisitTypeNames);

export const CaliVisitTypeToAppointmentTypeIdMap = {
  [VT.NEW]: AppointmentTypeIds.NewPatient,
  [VT.NSV]: AppointmentTypeIds.NewPatient,
  [VT.VNE]: AppointmentTypeIds.NewPatient,
  [VT.FUO]: AppointmentTypeIds.NewPatient,
  [VT.ROV]: AppointmentTypeIds.NewPatient,
  [VT.PTR]: AppointmentTypeIds.NewPatient,

  [VT.CPE]: AppointmentTypeIds.PhysicalExam,
  [VT.TCP]: AppointmentTypeIds.PhysicalExam,    // NOTE: not in spreadsheet ?? 
  [VT.PEF]: AppointmentTypeIds.PhysicalExam,
  [VT.HMV]: AppointmentTypeIds.PhysicalExam,
  [VT.WWE]: AppointmentTypeIds.PhysicalExam,
  [VT.TWW]: AppointmentTypeIds.PhysicalExam,
  [VT.NPV]: AppointmentTypeIds.PhysicalExam,
  [VT.NPE]: AppointmentTypeIds.PhysicalExam,

  // all VisitTypes not in this list will default to EstablishedPatient
  // see DefaultMissingVisitTypeAppointmentTypeId
  [VT.CON]: AppointmentTypeIds.EstablishedPatient,
  [VT.C30]: AppointmentTypeIds.EstablishedPatient,
  [VT.C40]: AppointmentTypeIds.EstablishedPatient,
  [VT.C45]: AppointmentTypeIds.EstablishedPatient,
};

// if the visitType or appoinmentTypeId is invalid or empty, then use NewPatient
export const DefaultInvalidVisitTypeAppointmentTypeId = AppointmentTypeIds.NewPatient;

// if the visitType or appoinmentTypeId is valid, but not in the mapped list above, use EstablishedPatient
export const DefaultMissingVisitTypeAppointmentTypeId = AppointmentTypeIds.EstablishedPatient;

// For Cali, map all forms to the same appointmentTypeId in the forms storage API
export const CaliDefaultDocAppointmentTypeId = AppointmentTypeIds.NewPatient;


// For Cali, telehealth visit types should have some required forms (not all forms required)
const CaliVisitTypeToRequiredTemplateKeysMap = {
  // Video Visit Types
  [VT.FUV]: [ PciDocTypes.REGISTRATION, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
  [VT.TEV]: [ PciDocTypes.REGISTRATION, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
  [VT.VNE]: [ PciDocTypes.REGISTRATION, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
  [VT.NEV]: [ PciDocTypes.REGISTRATION, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
  [VT.VPD]: [ PciDocTypes.REGISTRATION, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
  [VT.VVR]: [ PciDocTypes.REGISTRATION, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],

  // Phone Visit Types
  [VT.FPN]: [ PciDocTypes.REGISTRATION, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
  [VT.PHN]: [ PciDocTypes.REGISTRATION, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
  [VT.PSE]: [ PciDocTypes.REGISTRATION, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
  [VT.SPH]: [ PciDocTypes.REGISTRATION, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
  [VT.TEL]: [ PciDocTypes.REGISTRATION, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
  [VT.TEP]: [ PciDocTypes.REGISTRATION, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
  [VT.PCO]: [ PciDocTypes.REGISTRATION, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
  [VT.PSC]: [ PciDocTypes.REGISTRATION, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
  [VT.PRC]: [ PciDocTypes.REGISTRATION, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
  [VT.PRS]: [ PciDocTypes.REGISTRATION, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
  [VT.PHL]: [ PciDocTypes.REGISTRATION, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
  [VT.PHS]: [ PciDocTypes.REGISTRATION, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
};
const CaliDefaultVisitTypeToRequiredTemplateKeys = [ PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ];


// returns list of required required docTypes for this cdoVisitType.  defaults to false
// NOTE: this list may have items which are not expected nor displayed 
// and may get ignored by logic elsewhere.
export const GetCaliRequiredDocTypesByCdoVisitType = (cdoVisitType: string): PciDocTypes[] => {
  return CaliVisitTypeToRequiredTemplateKeysMap[cdoVisitType] || CaliDefaultVisitTypeToRequiredTemplateKeys;
};

// check if the docType is required for this cdoVisitType.  defaults to false
export const IsCaliDocTypeRequiredByCdoVisitType = (docType: PciDocTypes, cdoVisitType: string): boolean => {
  let requiredDocTypes: PciDocTypes[] = GetCaliRequiredDocTypesByCdoVisitType(cdoVisitType);
  return !!(docType && requiredDocTypes && requiredDocTypes.length > 0 && requiredDocTypes.includes(docType));
};

