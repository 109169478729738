import axios from "../interceptors/interceptors";
import moment from "moment";
import { HandleApiErrorMiddleware } from "./ApiErrorHandler";
import { CreateNewInsuranceCoverageItem } from "../features/insurance/insuranceSlice";
import { DefaultAppointmentTypeId, FhirInsuranceCoverageStatusCodes } from "../data/Fhir";
import { GetAppointmentTypeFromCdoVisitType, GetCdoSystem, GetVisitTypeFromCdoVisitType } from "../data/CDO";
import config from "../config";


const handleErrorSuppressed = (err: any) => {
  HandleApiErrorMiddleware("GetFhirAPI", err, true);
  return undefined;
}

export const GetPreferredLanguageAPI = async (pid) => {
  let apiCfg = config.api10;
  let url: string =
    apiCfg.url +
    "getPreferredLanguage?code=" +
    apiCfg.code +
    "&PatientId=" +
    pid;
  
  return axios
    .get(url)
    .then((response) => {
      return response?.data?.code;
    })
    .catch(handleErrorSuppressed);
};

export const GetRepresetativeDetailsAPI = async (pid) => {
  let apiCfg = config.guarantorApi;
  let url: string = apiCfg.url + "?PatientId=" + pid;

  return axios
    .get(url)
    .then((response) => {
      let res: any;
      if (response?.data?.guarantorDetails?.length > 0) {
        res = response.data.guarantorDetails[0];
      }
      return res;
    })
    .catch(handleErrorSuppressed);
};

export const GetPatientDemographicsAPI = async (pid) => {
  let apiCfg = config.api11;
  let url: string = apiCfg.url + "getDemographics?code=" + apiCfg.code + "&PatientId=" + pid;
  
  return axios
    .get(url)
    .then((response) => {
      let res: any;
      if (response?.data?.demographicsDetails?.length > 0) {
        res = response.data.demographicsDetails[0];
      }
      return res;
    })
    .catch(handleErrorSuppressed);
};

export const GetAllPatientDemographicsAPI = async (pid) => {
  let apiCfg = config.api11;
  let url: string = apiCfg.url + "getDemographics?code=" + apiCfg.code + "&PatientId=" + pid;
  
  return axios
    .get(url)
    .then((response) => {
      let res: any;
      let patient: any;
      let emergencyContact: any;
      if (response?.data) {
        if (response.data.demographicsDetails?.length > 0) {
          patient = response.data.demographicsDetails[0];
        }
        if (response.data.emergencyContacts?.length > 0) {
          emergencyContact = response.data.emergencyContacts[0];
        }
        if (patient || emergencyContact) {
          res = { patient, emergencyContact };
        }
      }
      return res;
    })
    .catch(handleErrorSuppressed);
};


const _findParticipantRowByReferencePrefix = (participants: any[], prefix: string): any => {
  let regex = new RegExp("^" + prefix + "/", "i");
  return (participants || []).find(v => {
    return (v?.actor?.reference || "").match(regex);
  });
};

const _findParticipantDisplayValueByReferencePrefix = (participants: any[], prefix: string): string => {
  let row: any = _findParticipantRowByReferencePrefix(participants, prefix);
  return row?.actor?.display || "";
};

export const GetAppointmentDetailsAPI = async (apptId: string, fallbackPatientId: string = ""): Promise<any> => {
  let apiCfg = config.api10;
  let cdoSystem = GetCdoSystem() || "";
  let url: string =
    apiCfg.url + "getAppointment?code=" + apiCfg.code +
    "&AppointmentId=" + cdoSystem + "|" + apptId;
  
  return axios
    .get(url)
    .then(async (response) => {
      let resource = response?.data?.resource;
      let patientId: string = fallbackPatientId;
      let patientFName: string = "";
      let patientLName: string = "";
      let locationName: string = "";
      let appt_dateobj: Date | undefined = undefined;
      let appt_date: string = "";
      let appt_time: string = "";
      let hasDetails: boolean = !!resource;

      // NOTE: patienId is expected to be at this specific index within the responses
      if (resource?.identifiers?.length >= 2 && resource.identifiers[1].value) {
        patientId = resource.identifiers[1].value;
      }

      // NOTE: we only grab the first name for now.  last name will have to come from somewhere else
      patientFName = _findParticipantDisplayValueByReferencePrefix(resource?.participants, "Patient");
      locationName = _findParticipantDisplayValueByReferencePrefix(resource?.participants, "Location");

      if (resource?.start) { 
        appt_dateobj = moment(resource.start).toDate();
        appt_date = moment(resource.start).format("dddd, MMMM Do");
        appt_time = moment.utc(resource.start).format("h:mm A");
      }

      // NOTE: appointmentTypeId may be a CDO specific visit type and not really an appointmentTypeId
      // after this logic completes
      // visitType should be the CDO specific visitType code
      // appointmentTypeId should be a number as a string.  ex: "4", "8", etc...
      let visitType: string = "";
      let appointmentTypeId = DefaultAppointmentTypeId;
      if (resource?.appointmentTypeId) { 
        visitType = GetVisitTypeFromCdoVisitType(resource.appointmentTypeId);
        appointmentTypeId = GetAppointmentTypeFromCdoVisitType(resource.appointmentTypeId, DefaultAppointmentTypeId);
      }

      return GetPractitionerAPI(patientId)
        .then((providerName) => {
          return {
            hasDetails,
            appt_dateobj,
            appt_date,
            appt_time,
            visitType,
            appointmentTypeId,
            patient_id: patientId,
            patient_fname: patientFName,
            patient_lname: patientLName,
            provider_name: providerName,
            location: locationName,
          };
        })
        .catch((error) => {
          handleErrorSuppressed(error);
          return {
            hasDetails,
            appt_dateobj,
            appt_date,
            appt_time,
            visitType,
            appointmentTypeId,
            patient_id: resource.identifiers[1].value,
            patient_fname: patientFName,
            patient_lname: patientLName,
            provider_name: "",
            location: locationName,
          };
        });
    })
    .catch(handleErrorSuppressed);
};

export const GetPractitionerAPI = async (pid) => {
  let apiCfg = config.api10;
  let url = apiCfg.url + "getPractitioner?code=" + apiCfg.code + "&PatientID=" + pid;
  
  return axios
    .get(url)
    .then((response) => {
      return response?.data?.fullName;
    })
    .catch(handleErrorSuppressed);
};


const _patchInsuranceCoverageItem = (coverage: any) => {
  if (coverage) {
    // convert the payorId string like this "Organization/UID" => "UID" and assign to insuranceProviderId
    coverage.insuranceProviderId = (coverage.payorId || "").replace(/^(\w+)\//i, "");

    if (!coverage.policyHolderName) {
      coverage.policyHolderName = coverage.policyHolder || "";
    }

    // patch policyHolderRelationToPatient 
    // text was the original value coming from the API.  
    // it was supposed to be the code, but was the value instead.
    // to kep the code/value consistent in the FE, let's split the apart now.
    let text = coverage.policyHolderRelationToPatient || "";
    let code = coverage.policyHolderRelationToPatientCode || "";
    let value = coverage.policyHolderRelationToPatientValue || "";
    if (!code) { code = text || value; }
    if (!value) { value = text || code; }
    coverage.policyHolderRelationToPatientCode = code;
    coverage.policyHolderRelationToPatientValue = value;
    delete coverage.policyHolderRelationToPatient;
    
    switch (coverage.status) {
      case "active":
        coverage.status = FhirInsuranceCoverageStatusCodes.Active;
        break;
      case "draft":
        coverage.status = FhirInsuranceCoverageStatusCodes.Draft;
        break;
      case "cancelled":
        coverage.status = FhirInsuranceCoverageStatusCodes.Cancelled;
        break;
      case "enteredinerror":
        coverage.status = FhirInsuranceCoverageStatusCodes.EnteredInError;
        break;
      default:
        break;
    }
  }
  return coverage;
};

export const GetInsuranceCoverageAPI = async (patientId: any) => {
  let apiCfg = config.api10;
  let url = apiCfg.url + "getCoverage?code=" + apiCfg.code + "&PatientID=" + patientId;

  return axios
    .get(url)
    .then((response) => {
      let res: any;
      // NOTE: we only care about insurance coverages that have an order of 1 or 2.  ignore all others
      let items = response?.data;
      if (!Array.isArray(items)) {
        items = [];
      }

      // find coverage1 and coverage2 specifically
      let coverage1: any = items.find((v) => Number(v?.order) === 1);
      let coverage2: any = items.find((v) => Number(v?.order) === 2);

      // if we don't find any with the primary order number, then the last record in the array would be the primary insurance.
      if (!coverage1 && items.length > 0) {
        let tmpItems: any[] = items.filter((v) => !v?.order);
        let lastItem: any =
          tmpItems.length > 0 ? tmpItems[tmpItems.length - 1] : null;
        if (lastItem) {
          lastItem.order = 1;
          coverage1 = lastItem;
        }
      }

      // check if coverage2 exists, but not coverage1.  that would be weird...  create an empty coverage1
      if (coverage2 && !coverage1) {
        coverage1 = CreateNewInsuranceCoverageItem();
      }

      if (coverage1 || coverage2) {
        res = [];
        if (coverage1) {
          res.push(coverage1);
        }
        if (coverage2) {
          res.push(coverage2);
        }
        res = res.map(_patchInsuranceCoverageItem);
      }
      return res;
    })
    .catch(handleErrorSuppressed);
};
